;(function($,window,undefined) {

	var Home = {

		$window: $(window),
		$groups: null,
		groups_index: 0,
		groups_max: 0,
		target_group: null,
		scroll_top: 0,
		scroll_btm: 0,

		/* ===============================================
		 * ページ読み込み後、Commonから一度だけ呼び出されます
		 * =============================================== */
		setupOnce: function(){
			Common.trace("Home -> setupOnce");

			var $window = Common.Window.$window;

			if(Common.LAYOUT_MODE == "sp"){
				$(".main").height($window.height());
			}

			/* -----------------------------------------------
			 * IE スクロールガタツキ調整
			 * ----------------------------------------------- */
			if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
				$('body').on("mousewheel", function () {
					event.preventDefault();
					var wd = event.wheelDelta;
					var csp = window.pageYOffset;
					window.scrollTo(0, csp - wd);
				});
			};

			// autoScroll("html,body", 100, 100, "swing");

			$("#blind").hide();

			Home.intro();
			// Home.setScrollEvent();
			Home.setFirstViewEvent();
			Home.setHeaderScroll();
			Home.setSwiper();
			Home.setThumbSize();


			// Home.setInstagram();

			// TweenMax.to("#blind", 1.3, { opacity: 0, delay: .2, ease: Power1.easeOut,
			// 	onStart: function(){
			// 		Home.intro();
			// 		Home.setScrollEvent();
			// 		Home.setFirstViewEvent();
			// 		Home.setHeaderScroll();
			// 		Home.setInstagram();
			// 	},
			// 	onComplete: function(){
			// 		Home.setSwiper();
			// 		$("#blind").hide();
			// 	}
			// });


			// ページ内アンカーの場合
			// $(".main__menu a[href^='#']").on("click", function(e){
			// 	if(e) e.preventDefault();
			// 	autoScroll($(this).attr("href"), 0, 800, "easeInOutQuart", $(".header").height() + 30);
			// });

			$(".main__menu__item-member").hover(
				function(e){
					$(".main__menu__sublist").addClass("is-active");
				},
				function(e){
					$(".main__menu__sublist").removeClass("is-active");
				}
			);

		},

		setThumbSize: function(){

			$(".member__slide__thumb").each(function(i,ele){
				var $_img = $(ele).find("img");
				var _w = $_img.width();
				var _h = $_img.height();

				if( _w > _h ){
					$_img.addClass("is-wh");
				}
				else if( _h > _w ){
					$_img.addClass("is-hw");
				};

				setTimeout(function(){
					$_img.addClass("is-anim");
				}, 400);

			});

		},

		setHeaderScroll: function(){

			var $window = Common.Window.$window;
			var $header = $(".header");
			var $global_btn_wrap = $(".globalBtnWrap");
			// var h_ = Math.max.apply( null, [document.body.clientHeight , document.body.scrollHeight, document.documentElement.scrollHeight, document.documentElement.clientHeight] );
			var h_ = Common.Window.height;
			if(Common.LAYOUT_MODE == "sp" && Common.is_safari){
				h_ = document.body.scrollHeight;
			};

			var is_header_active = false;
			var header_height = $header.height();

			var _s_top = 0;
			var _s_btm = 0;
			var _offset = $(".topics").offset().top - parseInt($header.height() + 40);

			$window.on({
				"scroll": function(){

					_s_top   = document.body.scrollTop || document.documentElement.scrollTop;
					_s_btm   = _s_top + Common.Window.height;

					// ヘッダートグル
					if(!is_header_active && _s_top >= _offset){
						$header.removeClass("is-deactive");
						$global_btn_wrap.removeClass("is-deactive");
						is_header_active = true;
					}else if(is_header_active && _s_top <= _offset){
						$header.addClass("is-deactive");
						$global_btn_wrap.addClass("is-deactive");
						is_header_active = false;
					};

				},

				"resize": function(){

					if(Common.LAYOUT_MODE == "sp") return;
					$window.trigger("scroll");

				}
			})
				.trigger("resize");


		},

		/* ===============================================
		 * instagram APIから取得
		 * =============================================== */
		setInstagram: function(){

			$(".instagram_entries .swiper-wrapper").empty();
			$.ajax({
				url: "https://api.instagram.com/v1/users/self/media/recent",
				data: { access_token: '5690728067.3eb0810.df74ec9cbc4e4190b79da8d29e03beaf' },
				dataType: "jsonp",
				error: function(jqXHR, textStatus, errorThrown) {
					$(".instagram_entries").text(textStatus);
				},
				success: function(data, textStatus, jqXHR) {
					// console.log("sucsess");
					// console.log(data);

					$(".gallery__list").empty();

					for (var i = 0, length = data.data.length; i < length; i++) {
						var d = data.data[i];

					// console.log(d.link);
					// .gallery__item: a.gallery__item__anc(target="_blank" href=""): figure.gallery__item__thumb: img(src="#{DIR_IMG_PAGE}member_thumb_dummy.jpg",width=140,height=140,alt="")

						$(".gallery__list").append(

							$('<li class="gallery__item">')
								.append($('<a class="gallery__item__anc">')
									.attr("href", d.link)
									.attr("target", "_blank")
								.append($('<figure class="gallery__item__thumb">')
									.attr("href", d.link)
									.attr("target", "_blank")
								.append($("<img>")
									.attr("src", d.images.standard_resolution.url)
									//								.attr("alt", d.caption.text)
									.attr("width", "140").attr("height", 140)
									)
								)
							)
						);
					}

				}
			});

		},

		setFirstViewEvent: function(){

			// var mousewheelevent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
			var $body = $("html,body");
			var $window = Common.Window.$window;
			var mousewheelevent = "mousewheel";
			var $main_scroll = $(".main__scroll");
			var $target = $(".topics");
			var _s_top = parseInt( $window.scrollTop());
			var _is_scroll = false;

			$main_scroll.on('click', function(e) {
				if(_is_scroll) return;
				_is_scroll = true;
				// TweenMax.to($main_scroll, .4, {ease:Power2.easeInOut, opacity: 0 });
				TweenMax.to($body, 1.1, {ease:Power2.easeInOut, scrollTop:$target.offset().top - parseInt($(".header").height() + 30),
					onComplete: function(){
						$window.off("mousewheel");
						$window.trigger("scroll");
						_is_scroll = false;
					}
				});
			});

			if($(".main").length <= 0){
				$window.off(mousewheelevent);
			}else{

				$window.on(mousewheelevent, function(event, delta, deltaX, deltaY){
					event.preventDefault();

					// トップビジュアルからコンテンツまでスクロール
					if(_s_top === 0) {
						deltaY = event.originalEvent.deltaY;
						if(deltaY > 0) {
							$main_scroll.trigger("click");
						}
					}
					else{
						$window.off(mousewheelevent);
					};

				});

			};

		},

		resetSwiper: function(){

			$(".member__pagination").remove();
			$(".member__left").remove();
			$(".member__right").remove();

		},
		setSwiper: function(){

			if(Common.LAYOUT_MODE == "pc" && $(".member__slide").length <= 3){
				this.resetSwiper();
				return;
			};
			if(Common.LAYOUT_MODE == "sp" && $(".member__slide").length <= 2){
				this.resetSwiper();
				return;
			};

			var member_slide = new Swiper('.member__container', {
				speed: 800,
				grabCursor: true,
				slidesPerView: 3,
				spaceBetween: 60,
				loop: true,
				initialSlide: 2,
				centeredSlides: true,
				autoplay: {
					delay: 1600
				},
				navigation: {
					nextEl: '.member__right',
					prevEl: '.member__left'
				},
				// spaceBetween: 30,
				pagination: {
					el: '.member__pagination',
					clickable: true,
					type: 'bullets'
				},
				onSlideChangeEnd: function (e) {

				},
				breakpoints: {
					// when window width is <= 320px
					768: {
						freeMode: true,
						loop: false,
						slidesPerView: "auto",
						spaceBetween: 30,
						centeredSlides: false,
						initialSlide: 0
					}
				}
			});

		},

		/* ===============================================
		 * イントロ
		 * =============================================== */
		intro: function(){

			// var $text = $(".main_logo svg").find('path,polygon,polyline');
			// var n = 0;
			// var fv_y = 0;
			// var ua = window.navigator.userAgent.toLowerCase();
			// var is_safari = ua.indexOf('safari') != -1;
			// var direc = (is_safari)? 1: -1;
			// var cnt = 0;
      //
			// TweenMax.fromTo($(".main_logo"), .8, { y: -12, opacity: 0}, { y: 0, opacity: 1, delay: .2, ease: Power2.easeOut});
			// TweenMax.fromTo($(".main_bg"), 1.8, { opacity: 0}, { y: 0, opacity: 1, delay: .6, ease: Power1.easeOut});

			// var $arr_path = $("#mainlogoSvg").find('path,polygon,polyline');
			var n = 0;
			var fv_y = 0;
			var is_safari = Common.is_safari;
			var direction = (is_safari)? 1: -1;
			var cnt = 0;
			var base_delay = 1;
			var $window = Common.Window.$window;
			var $main_scroll = $(".main__scroll");

			var main_logo_handle = document.querySelector(".main__logo__handle");
			var main_logo_body = document.querySelector(".main__logo__body");
			var main_logo_label = document.querySelector(".main__logo__label");
			var main_logo_text = document.querySelector(".main__logo__text");
			var main_logo_text01 = document.querySelector(".main__logo__text-1");
			var main_logo_text02 = document.querySelector(".main__logo__text-2");
			var main_logo_text03 = document.querySelector(".main__logo__text-3");
			var main_logo_text04 = document.querySelector(".main__logo__text-4");
			var main_logo_text05 = document.querySelector(".main__logo__text-5");

			var main_logo_handle_len = main_logo_handle.getTotalLength();
			var main_logo_body_len = main_logo_body.getTotalLength();
			// var main_logo_label_len = main_logo_label.getTotalLength();
			var main_logo_text01_len = main_logo_text01.getTotalLength();
			var main_logo_text02_len = main_logo_text02.getTotalLength();
			var main_logo_text03_len = main_logo_text03.getTotalLength();
			var main_logo_text04_len = main_logo_text04.getTotalLength();
			var main_logo_text05_len = main_logo_text05.getTotalLength();


			// $(".main__menu").hide();
			// $(".copyright").hide();
			// $(".snsMenu").hide();
			// $(".main__scroll").hide();

			window.scrollTo(0,0);

			// TweenMax.fromTo(main_logo_handle, .8, { strokeOpacity: 1, opacity: 1, fillOpacity: 0, strokeDasharray: main_logo_handle_len, strokeDashoffset: main_logo_handle_len * direction}, { strokeOpacity: 1, fillOpacity: 0, strokeDashoffset:0, delay: base_delay + 1.1, ease: Power2.easeInOut });
			// TweenMax.fromTo(main_logo_handle, .4, { fillOpacity: 0}, { strokeOpacity: 0, fillOpacity: 1, delay: base_delay + 2.0, ease: Power2.easeOut });
			TweenMax.fromTo(main_logo_body, .8, { display: "none", strokeOpacity: 1, opacity: 1, fillOpacity: 0, strokeDasharray: main_logo_body_len, strokeDashoffset: main_logo_body_len * direction}, { strokeOpacity: 1, fillOpacity: 0, strokeDashoffset:0, delay: base_delay + 0, ease: Power1.easeInOut,
			onStart: function(){
				$(main_logo_body).show();
			}
			});
			TweenMax.fromTo(main_logo_body, .4, { fillOpacity: 0}, { strokeOpacity: 0, fillOpacity: 1, delay: base_delay + .8, ease: Power2.easeOut,
			onComplete: function(){
				TweenMax.fromTo(main_logo_handle, .4, { y: 30, opacity: 1}, { y: 0, ease: Power2.easeOut });
			}
			});
			TweenMax.fromTo(main_logo_label, .4, { x: "50%", scaleX: 0, fillOpacity: 1}, { x: "0%", scaleX: 1, width: "100%", fillOpacity: 1, delay: base_delay + .9, ease: Power2.easeOut });

			TweenMax.fromTo(main_logo_text01, .8, { display: "none", strokeOpacity: 1, opacity: 1, fillOpacity: 0, strokeDasharray: main_logo_text01_len, strokeDashoffset: main_logo_text01_len * direction}, { strokeOpacity: 1, fillOpacity: 0, strokeDashoffset:0, delay: base_delay + 1.4, ease: Power1.easeInOut });
			TweenMax.fromTo(main_logo_text02, .8, { display: "none", strokeOpacity: 1, opacity: 1, fillOpacity: 0, strokeDasharray: main_logo_text02_len, strokeDashoffset: main_logo_text02_len * direction}, { strokeOpacity: 1, fillOpacity: 0, strokeDashoffset:0, delay: base_delay + 1.4, ease: Power1.easeInOut });
			TweenMax.fromTo(main_logo_text03, .8, { display: "none", strokeOpacity: 1, opacity: 1, fillOpacity: 0, strokeDasharray: main_logo_text03_len, strokeDashoffset: main_logo_text03_len * direction}, { strokeOpacity: 1, fillOpacity: 0, strokeDashoffset:0, delay: base_delay + 1.4, ease: Power1.easeInOut });
			TweenMax.fromTo(main_logo_text04, .8, { display: "none", strokeOpacity: 1, opacity: 1, fillOpacity: 0, strokeDasharray: main_logo_text04_len, strokeDashoffset: main_logo_text04_len * direction}, { strokeOpacity: 1, fillOpacity: 0, strokeDashoffset:0, delay: base_delay + 1.4, ease: Power1.easeInOut });
			TweenMax.fromTo(main_logo_text05, .8, { display: "none", strokeOpacity: 1, opacity: 1, fillOpacity: 0, strokeDasharray: main_logo_text05_len, strokeDashoffset: main_logo_text05_len * direction}, { strokeOpacity: 1, fillOpacity: 0, strokeDashoffset:0, delay: base_delay + 1.4, ease: Power1.easeInOut,
			onStart: function(){
				$(main_logo_text01).show();
				$(main_logo_text02).show();
				$(main_logo_text03).show();
				$(main_logo_text04).show();
				$(main_logo_text05).show();
			}
			});

			TweenMax.fromTo(main_logo_text01, .8, { fillOpacity: 0}, { strokeOpacity: 0, fillOpacity: 1, delay: base_delay + 2.2, ease: Power2.easeOut });
			TweenMax.fromTo(main_logo_text02, .8, { fillOpacity: 0}, { strokeOpacity: 0, fillOpacity: 1, delay: base_delay + 2.2, ease: Power2.easeOut });
			TweenMax.fromTo(main_logo_text03, .8, { fillOpacity: 0}, { strokeOpacity: 0, fillOpacity: 1, delay: base_delay + 2.2, ease: Power2.easeOut });
			TweenMax.fromTo(main_logo_text04, .8, { fillOpacity: 0}, { strokeOpacity: 0, fillOpacity: 1, delay: base_delay + 2.2, ease: Power2.easeOut });
			TweenMax.fromTo(main_logo_text05, .8, { fillOpacity: 0}, { strokeOpacity: 0, fillOpacity: 1, delay: base_delay + 2.2, ease: Power2.easeOut });

			TweenMax.fromTo(".main__menu__item", .6, { y: 30, opacity: 0}, { y: 0, opacity: 1, delay: base_delay + 2.8, ease: Power2.easeOut });
			TweenMax.fromTo(".copyright", .6, { x: -30, opacity: 0}, { x: 0, opacity: 1, delay: base_delay + 2.8, ease: Power2.easeOut });
			TweenMax.fromTo(".snsMenu", .6, { x: 30, opacity: 0}, { x: 0, opacity: 1, delay: base_delay + 2.8, ease: Power2.easeOut });
			TweenMax.fromTo(".main__scroll", .6, { y: -10, opacity: 0}, { y: 0, opacity: 1, delay: base_delay + 2.8, ease: Power2.easeOut });
			TweenMax.fromTo("#mainlogoSvg", .6, { y: 80, opacity: 1}, { y: 0, delay: base_delay + 2.8, ease: Power2.easeOut,
			onComplete: function(){
				// $(main_logo_handle).removeAttr("style");
				// $(main_logo_body).removeAttr("style");
				// $(main_logo_label).removeAttr("style");
				// $(main_logo_text).removeAttr("style");
				$(".main__menu__item").removeAttr("style");
				$(".copyright").removeAttr("style");
				$(".snsMenu").removeAttr("style");
				$("#mainlogoSvg").removeAttr("style");
				$main_scroll.removeAttr("style");
				$main_scroll.addClass("is-active");

				$window.on("scroll", function(){

					var __s_top = parseInt( $window.scrollTop());
					if(__s_top > 0){
						$main_scroll.addClass("is-disable");
					}else{
						$main_scroll.removeClass("is-disable");
					}

				});

			}

			});

		},

		/* ===============================================
		 * スキップイントロ
		 * =============================================== */
		skipIntro: function(){



		},

		/* ===============================================
		 * スクロールイベント登録
		 * =============================================== */
		setScrollEvent: function(){

			/* -----------------------------------------------
			 * 画像のスクロールフェードイン
			 * ----------------------------------------------- */
			Home.$groups = $(".js-slideIn");
			Home.groups_index = 0;
			Home.groups_max = Home.$groups.length;
			Home.target_group = $(Home.$groups[0]);
			Home.scroll_top   = document.body.scrollTop || document.documentElement.scrollTop;
			Home.scroll_btm   = Home.scroll_top + Common.Window.height;

			Home.$groups.each(function(i,ele){
				var $ele = $(ele);
				$ele.addClass("works-entry-index"+String(i))
				if($ele.offset().top > Home.scroll_btm - 200){
					$ele.css({opacity: 0});
				}
				else{
					Home.groups_index++;
				};

			});

			if(Home.groups_index < Home.groups_max){
				Home.target_group = $(Home.$groups[Home.groups_index]);
				Home.$window.on('scroll.effect', Home.onScroll).trigger("scroll");
			};

		},

		/* -----------------------------------------------
		 * スクロールイベント
		 * ----------------------------------------------- */
		effect_end: false,
		onScroll: function (e) {

			Home.scroll_top   = document.body.scrollTop || document.documentElement.scrollTop;
			Home.scroll_btm   = Home.scroll_top + Common.Window.height;
			Home.scroll_mdl   = Home.scroll_top + Common.Window.height / 2;

			// console.log("Home.scroll_mdl: " + Home.scroll_mdl);
			// console.log("Home.logo_top: " + Home.logo_top);

			if(Home.scroll_btm - Common.Window.height * 0.3 > Home.target_group.offset().top){

				Home.fadeIn( Home.target_group );
				Home.groups_index++;

				if(Home.groups_index < Home.groups_max){
					Home.target_group = $(Home.$groups[Home.groups_index]);
				}
				else{
					Home.effect_end = true;
					Home.$window.off('scroll.effect', Home.onScroll)
				}
			}
		},

		fadeIn: function( opt_target ){
			TweenMax.fromTo(opt_target, .9, { y: 20 },{ opacity: 1, y: 0, ease: Power2.easeOut,
				onComplete: function(){
					$(opt_target).css({
						opacity: "",
						top: ""
					})
					opt_target = null;
				}
			})

		}

	};

	Common.Page = Home;

})(jQuery,window);